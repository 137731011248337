.mobile--hidden {
    @include media-breakpoint-down(md) {
        display: none !important;
    }
}

.desktop--hidden {
    @include media-breakpoint-up(lg) {
        display: none !important;
    }
}

strong {
    font-family: $font-family-bold;
}

.w-mobile-first {
    @include media-breakpoint-down(md) {
        order: 1;
    }
}

.w-mobile-second {
    @include media-breakpoint-down(md) {
        order: 2;
    }
}
.root {
    &-wrap {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;

        .sidebar,
        .dashboard {
            flex: 1;
        }
    }
}

.rotate {
    .drop-icon svg {
        transform: rotate(-180deg);
    }
}

.menu-toggle {
    display: block;
    margin-right: 20px;

    @include media-breakpoint-up(xl) {
        display: none;
    }
}

.menu-active {
    .root {
        overflow: hidden;
    }
}