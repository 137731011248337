.support-bar {
    margin: 0 30px;

    position: absolute;
    bottom: 20%;

    @include media-breakpoint-up(xxl) {
        margin: 0 50px;
    }

    @include media-breakpoint-only(xl) {
        margin: 0 30px;
    }

    @include media-breakpoint-down(lg) {
        position: relative;
        bottom: 0;
        top: 20vh;
    }

    &__wrapper {
        padding: 15px;
        border-radius: 5px;
        background-color: rgba(222, 56, 20, 0.17);
        display: inline-block;

        @include media-breakpoint-down(lg) {
            display: block;
        }


        a {
            @include transition;
            display: inline-flex;
            align-items: center;

            min-width: auto;

            @include media-breakpoint-down(lg) {
                display: flex;
            }

            svg {
                @include transition;
                margin-right: 10px;
                fill: $color-white;
            }

            &:hover {
                svg {
                    fill: $color-btn-red;
                }
            }

            &.disabled {
                pointer-events: none;
                opacity: .5;
            }
        }
    }

    &__online,
    &__offline {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        text-transform: uppercase;
        font-family: $font-family-semi-bold;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 1px;

        &::before {
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 50%;

            margin-right: 5px;
        }
    }

    &__online {
        color: #aaffa8;

        &::before {
            background-color: #aaffa8;
        }
    }

    &__offline {
        color: $color-btn-red;

        &::before {
            background-color: $color-btn-red;
        }
    }
}