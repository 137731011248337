.global {
    &-link {
        @include transition;

        font-family: $font-family-semi-bold;
        color: $color-btn-red;

        cursor: pointer;

        &:hover {
            text-decoration: none;
            color: $color-default-headline;
        }
    }

    &-headline {
        font-family: $font-family-bold;
        font-size: 30px;
        line-height: 40px;
        color: $color-default-headline;

        @include media-breakpoint-up(lg) {
            font-size: 38px;
            line-height: 48px;
        }

        @include media-breakpoint-down(sm) {
            font-size: 22px;
            line-height: 30px;
        }

        &--small {
            font-size: 15px;
            line-height: 22px;

            @include media-breakpoint-up(lg) {
                font-size: 18px;
                line-height: 24px;
            }

            span {
                font-family: $font-family-regular;
                font-style: italic;
                font-size: 15px;
            }
        }

        &--medium {
            font-size: 18px;
            line-height: 24px;

            @include media-breakpoint-up(lg) {
                font-size: 22px;
                line-height: 26px;
            }
        }

        &--large {
            font-size: 22px;
            line-height: 26px;

            @include media-breakpoint-up(lg) {
                font-size: 32px;
                line-height: 42px;
            }

            span {
                font-family: $font-family-regular;
                display: block;
                color: rgba(0, 0, 0, 0.54);
                font-size: 18px;
                line-height: 24px;
                margin-top: 10px;
            }
        }

        &.centered {
            text-align: center;
            margin: 0 auto;
        }
    }

}