.dashboard-usage {
    &-cta {
        display: block;
        display: inline-block;
        text-align: center;
        width: 100%;
        margin: 25px auto 0;

        .btn {
            min-width: auto;
        }
    }

    .custom-height {
        @include media-breakpoint-up(xl) {
            canvas {
                min-height: 240px;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        .flex-inner {
            display: flex;
            align-items: center;

            .dashboard__proxy-usage__chart {
                min-height: 250px;
            }

           .dashboard__proxy-usage__filter {
               flex: 1;
            }

            .dashboard__proxy-usage__header {
                position: relative;
                top: 7px;
                margin-right: 20px;
            }

            .dashboard__proxy-usage__dropdown {
                min-width: 200px;
            }
        }
    }

    @include media-breakpoint-between(sm,md) {
        .flex-inner {
            display: flex;
            align-items: center;

           .dashboard__proxy-usage__filter {
               flex: 1;
            }

            .dashboard__proxy-usage__header {
                position: relative;
                top: 7px;
                margin-right: 20px;
            }

            .dashboard__proxy-usage__dropdown {
                min-width: 200px;
            }
        }
    }
}