/**
 * Tables
 */

.table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    &--cards {
        border-spacing: 0 10px !important;
    }

    &--cards,
    &--invoice {
        table-layout: auto;
        border-collapse:separate;
        border-spacing: 0 6px;

        @include media-breakpoint-down(sm) {
            margin: auto;
            overflow-x: auto;
            display: block;
            width: fit-content;
            width: 100%;
        }

        @include media-breakpoint-down(sm) {
            td {
                padding: 5px 2px !important;

                &:nth-child(1) {
                    padding: 5px 2px 5px 5px !important;
                }

                &:nth-child(5) {
                    padding: 5px 5px 5px 2px !important;
                }
            }
        }

        th,
        td {
            text-align: left;
            padding: 10px;

            color: $color-global-headline-blue;

            font-family: $font-family-bold;
            font-size: 14px;
            line-height: 22px;

            background-color: rgba(218, 230, 245, 0.53);

            &:first-of-type {
                border-radius: 4px 0 0 4px;
            }

            &:not(:first-of-type) {
                text-align: center;
            }

            &:last-of-type {
                text-align: right;
                border-radius: 0 4px 4px 0;
            }

            @include media-breakpoint-down(sm) {
                white-space: nowrap;
            }

        }
    }
}