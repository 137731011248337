.dashboard__footer {
    padding: 20px 0;

    &-inner {
        border-top: 1px solid rgba(0, 0, 0, 0.07);
        padding-top: 20px;
        width: 100%;

        display: grid;
        grid-template-columns: repeat(3, 1fr);

        @include media-breakpoint-down(sm) {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .logo {
        max-width: 20px !important;
        margin: 0 !important;

        @include media-breakpoint-down(sm) {
            margin: 0 auto 20px !important;
        }
    }

    p {
        font-family: $font-family-regular;
        font-size: 13px;
        line-height: 22px;
        color: $color-default-copy;
        text-align: center;
    }

    nav {
        text-align: right;

        @include media-breakpoint-down(sm) {
            text-align: center;
            margin-top: 5px;
        }

        a {
            font-family: $font-family-bold;
            font-size: 13px;
            line-height: 22px;
            color: rgba(24, 30, 45, 0.69);

            &:not(:last-of-type) {
                margin-right: 20px;
            }


            &:hover {
                color: $color-default-headline;
                text-decoration: none;
            }
        }
    }
}