// VARS
$circle-width: 0.05em;
// colors default
$primary-color: $color-circle-filled;
$secondary-color: $color-circle-before;

.rect-auto{
	clip: rect(auto, auto, auto, auto);
    @include transition;
}

.pie {
	position: absolute;
	border: $circle-width solid $primary-color;
	width: 1 - (2 * $circle-width);
	height: 1 - (2 * $circle-width);
	clip: rect(0em, 0.5em, 1em, 0em);
	border-radius: 50%;
	transform: rotate(0deg);
    @include transition;
}

.pie-fill {
	transform: rotate(180deg);
}

// main
.progress-circle {
    &-copy {
        font-family: $font-family-bold;
        font-size: 60px;
        line-height: 60px;
        color: $color-circle-filled;

        position: absolute;

        span {
            font-family: $font-family-semi-bold;
            display: block;
            color: rgba(0, 0, 0, 0.49);
            font-size: 12px;
            line-height: 15px;
        }

		small {
			font-size: 14px;
			line-height: 16px;
		}
    }

	*,
    *:before,
    *:after {
		box-sizing: content-box;
	}

	position: relative;
	font-size: 220px;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	background-color: $secondary-color;
    margin: 25px auto 0;

    // @include media-breakpoint-down(md) {
    //     font-size: 120px;
    // }

	> .progress-circle-copy {
		position: absolute;
		width: 100%;
		z-index: 1;
		left: 50%;
		top: 50%;
		display: block;
		text-align: center;
		white-space: nowrap;
        transform: translateY(-50%) translateX(-50%);
	}

	// background inside the circle
	&:after{
		position: absolute;
		top: $circle-width;
		left: $circle-width;
		display: block;
		content: " ";
		border-radius: 50%;
		background-color: $color-white;
		width: 1 - (2 * $circle-width);
		height: 1 - (2 * $circle-width);

	}

	// the slice (mask)
	.slice {
		position: absolute;
		width: 1em;
		height: 1em;
		clip: rect(0em, 1em, 1em, 0.5em);
	}

	// circle to show the status
	.bar {
		@extend .pie;
	}


	// loop to create all needed elements automatically
	@for $j from 51 through 100 {

		&[data-remain='#{$j}'] .slice {
			@extend .rect-auto;
		}

		&[data-remain='#{$j}'] .bar:after{
			@extend .pie-fill;
		}

		&[data-remain='#{$j}'] .fill{
			@extend .pie;
			@extend .pie-fill;
		}

	}

	// loop to rotate all 100 circles
	@for $j from 1 through 100 {
		&[data-remain='#{$j}'] .bar {
			transform: rotate((360/100*$j) + deg);
		}
	}
}