$side-menu-width: 330px;
$side-menu-width-desktop-medium: 330px;
$side-menu-width-desktop: 380px;

/* TOGGLE SIDEBAR */
.menu-active {
    @include media-breakpoint-down(lg) {
        .dashboard {
            // moves page to right
            transform: translate3d(330px, 0, 0);
        }

        .sidebar {
            transform: translate3d(0,0,0);
        }
    }
}

.sidebar {
    @include transition;
    background-color: $color-bg-dashboard-sidebar;
    justify-content: flex-end;
    position: fixed;
    top: 0;
    bottom: 0;
    width: $side-menu-width-desktop-medium;
    z-index: 10;
    min-width: $side-menu-width;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    @include media-breakpoint-up(xxl) {
        width: $side-menu-width-desktop;
    }

    @include media-breakpoint-up(lg) {
        min-height: calc(100vh * 1.059);
    }

    @include media-breakpoint-only(lg) {
        width: $side-menu-width;
    }

    @include media-breakpoint-down(lg) {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        min-height: 100%;
        @include transition;
        transform: translate3d(-100%, 0, 0); // hides menu
        overflow: scroll;
    }

    &__root {
        box-sizing: border-box;
        display: flex;
        height: 100%;
        flex-direction: column;
    }

    nav {
        position: relative;
        flex-grow: 1;
        overflow-y: auto;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .logo {
        max-width: 35px;
        margin: 90px auto;

        @include media-breakpoint-down(sm) {
            margin: 50px auto;
        }

        @include media-breakpoint-up(xxl) {
            max-width: 45px;
        }
    }
}

.sidebar::-webkit-scrollbar {
    width: 0px; /* For Chrome, Safari, and Opera */
}