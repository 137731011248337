label {
  font-family: $font-family-semi-bold;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

/* For Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Webkit browsers like Safari and Chrome */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-field {
  margin-bottom: 15px;

  @include media-breakpoint-down(lg) {
    margin-bottom: 10px;
  }
}

.form-field__wrapper--input-checkbox {
  position: relative;
}

.input-unstyled {
  appearance: none;
  box-sizing: border-box;
  outline: 0;
  box-shadow: none;
  background: none !important;
  border: 0;
  border-radius: 0;
  font-size: 14px;
  color: rgba($color-black, .78);
  text-align: center;
}

.input-textarea {
  font-family: $font-family-semi-bold;
  appearance: none; // Removes awkward default styles on some inputs for iOS
  box-sizing: border-box;
  width: 100%;
  outline: 0;
  box-shadow: none;
  background-color: $color-text-area-bg-color;
  color: rgba($color-black, .78);
  border-radius: 5px;
  font-size: 14px;
  padding: 15px;
  background-image: none !important;
}

.input-text {
  font-family: $font-family-semi-bold;
  appearance: none; // Removes awkward default styles on some inputs for iOS
  box-sizing: border-box;
  width: 100%;
  outline: 0;
  box-shadow: none;
  background-color: $color-white;
  color: rgba($color-black, .78);
  border-radius: 16px;
  font-size: 14px;
  height: 46px;
  padding: 0 15px;
  background-image: none !important;
}

// input type text / password
.select {
  font-family: $font-family-semi-bold;
  box-sizing: border-box;
  width: 100%;
  height: 46px;
  padding: 0 35px 0 10px;
  border-radius: 16px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  outline: 0;
  box-shadow: none;
  background-color: $color-white;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Some browsers will not display the caret when using calc, so we put the fallback first */
  background: url('/assets/img/arrow-down.svg') $color-white no-repeat 98.5% !important; /* !important used for overriding all other customisations */
  background: url('/assets/img/arrow-down.svg') $color-white no-repeat calc(100% - 15px) !important; /* Better placement regardless of input width */

  color: rgba($color-black, .78);
  border: none;
  cursor: pointer;

  @media all and (-ms-high-contrast: none) {
    padding-right: 0; /* IE10 */

    *::-ms-backdrop,
    body {
      padding-right: 0; /* IE11 */
    }
  }

  &:disabled {
    opacity: .5;
    color: rgba($color-black, .28);
    border-color: $color-white;
  }
}

.form-field {
  // general wrapper for form-field elements
  &__wrapper {
    margin-bottom: 20px;

    &.has-error {
      label {
        color: $color-error;
      }

      input {
        color: $color-error;
        border-color: $color-error;
      }
    }

    &--text,
    &--password,
    &--select,
    &--number {
      position: relative;
    }

    &-textarea {
      border: 0;
      position: relative;
    }
  }

  // general label-styling in form-fields
  &__label {
    font-family: $font-family-semi-bold;
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    margin-bottom: 10px;
    color: $color-default-headline;

    &.has-error {
      color: $color-error;
    }


    // label behavior in combination with text- and password-inputs
    .form-field__wrapper--number &,
    .form-field__wrapper--text &,
    .form-field__wrapper--password & {
      @include transition($duration: .15s);
      font-size: 14px;
      font-weight: 500;
      background-color: $color-white;
      color: rgba($color-black, .78);
      opacity: .78;
      position: absolute;
      padding: 0 5px;
      left: 10px;
      top: -10px;

      &:hover {
        cursor: text;
      }
    }
  }
}
