.section-login {

    .dashboard__footer {
        margin-top: -20px;

        .container {
            max-width: 100% !important;
            padding: 0;
        }

        .dashboard__footer-inner {
            padding: 20px 20px 0;
        }
    }

    .logo {
        position: relative;
        max-width: 55px;
        margin: 80px 0 50px;
    }

    &__content,
    &__layer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__layer {
        img {
            max-width: 70%;
        }
    }

    &__content {
        background-color: $color-bg-default;
        height: 100vh;
        min-height: 800px;
        position: relative;
        padding: 0 25px;

        .section-login--large & {
            height: 100vh;

            @include media-breakpoint-down(md) {
                height: 100%;
                min-height: auto;
            }
        }

        @include media-breakpoint-down(md) {
            height: 100%;
            min-height: auto;
        }

        &-headline,
        form,
        &-register {
            width: 100%;
            max-width: 550px;

            &.has-small-inner {
                max-width: 400px;
            }
        }

        &-headline {
            font-family: $font-family-bold;
            font-size: 30px;
            line-height: 40px;
            color: $color-default-headline;

            text-align: center;

            @include media-breakpoint-down(lg) {
                font-size: 22px;
                line-height: 30px;
            }
        }

        &-copy {
            font-family: $font-family-regular;
            font-size: 20px;
            line-height: 24px;
            color: $color-default-copy;
            margin-top: 10px;

            text-align: center;

            @include media-breakpoint-down(md) {
                font-size: 14px;
                line-height: 22px;
            }
        }

        &-loginform {
            margin: 50px 0 0;

            @include media-breakpoint-down(md) {
                margin-bottom: 25px;
            }

            .form-field  {
                margin-bottom: 30px;

                .reset-password {
                    float: right;
                    margin: 10px 0;
                }

                &__wrapper-login {
                    width: 100%;
                    margin: 30px auto 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &__wrapper-account {
                    color: $color-default-copy;
                    display: block;
                    text-align: center;
                }
            }
        }
    }

    &__layer {
        background: $color-bg-login;
        height: 100vh;
        min-height: 800px;
        position: relative;

        .section-login--large & {
            min-height: 1100px;

            @include media-breakpoint-down(md) {
                height: 100%;
                min-height: auto;
                padding: 100px 0;
            }
        }

        @include media-breakpoint-down(md) {
            height: 100%;
            min-height: auto;
            padding: 100px 0;
        }
    }

    .mobile--hidden {
        position: relative;
        &::before {
            content: '';
            width: 15px;
            height: 100%;
            min-height: 800px;

            background-color: rgba(24, 30, 45, 0.08);
            display: block;

            position: absolute;
            top: 0;
            left: -5px;

            transform: rotate(0.5deg);
        }

        &::after {
            content: '';
            width: 15px;
            height: 100%;
            min-height: 800px;

            background-color: rgba(24, 30, 45, 0.05);
            display: block;

            position: absolute;
            top: 0;
            left: -10px;

            transform: rotate(0.5deg);
        }
    }
}