// /**
//  * Buttons
//  */
$btn-font-size: 16px !default;
$btn-font-size-mobile: 14px !default;

$btn-padding-x: 35px !default;
$btn-padding-y: 10px !default;

$line-height-base: 30px !default;


/* Common button styles */
.btn {
    @include transition;

    font-family: $font-family-semi-bold;
    display: inline-block;
    padding: $btn-padding-y $btn-padding-x;
    color: $color-white;
    vertical-align: middle;
    text-align: center;
    white-space: nowrap;
    font-size: $btn-font-size;
    font-weight: inherit;
    line-height: $line-height-base;
    letter-spacing: .2px;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    border-radius: 8px;
    // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.07);
    outline: 0;
    border: 0 !important;
    background: none;
    position: relative;
    z-index: 1;
    min-width: 140px;

    &:focus {
        outline: none;
    }

    &:disabled {
        pointer-events: none;
    }

    &:hover {
        text-decoration: none !important;
    }

    @include media-breakpoint-down(sm) {
        font-size: 13px !important;
        letter-spacing: 1px;
    }
}
.btn--primary {
	color: $color-white;
	overflow: hidden;
    border: 1px solid $color-btn-red !important;
    padding: 10px $btn-padding-x;

    background: $color-btn-red;
    transition: all .3s ease-out;

    &:hover {
        background: transparent;
        color: $color-btn-red;

    }
}

.btn--purchase {
	color: $color-white;
	overflow: hidden;
    border: 1px solid $color-btn-download !important;
    padding: 10px $btn-padding-x;

    background: $color-btn-download;
    transition: all .3s ease-out;

    &:hover {
        background: transparent;
        color: $color-btn-download;

    }
}

.btn--download {
	color: $color-white;
	overflow: hidden;
    border: 1px solid $color-btn-download !important;
    padding: 5px 15px;
    min-width: auto;
    font-size: 13px;

    background: $color-btn-download;
    transition: all .3s ease-out;

    &:hover {
        background: transparent;
        color: $color-btn-download;

    }
}

.btn--outlined {
	background: transparent;
	color: $color-btn-red;
	overflow: hidden;
    border: 1px solid $color-btn-red !important;

    background: transparent;
    transition: all .3s ease-out;


    &:hover {
        background: $color-btn-red;
        text-decoration: none;
        color: $color-white;
    }
}

.btn--action {
	color: $color-white;
	overflow: hidden;
    border: 1px solid $color-btn-action !important;
    padding: 5px 15px;
    min-width: auto;
    font-size: 13px;
    min-width: auto;
    display: flex;
    align-items: center;

    background: $color-btn-action;
    transition: all .3s ease-out;

    svg {
        margin-right: 10px;
        fill: $color-white;
    }

    &:hover {
        background: transparent;
        color: $color-btn-action;

        svg {
            fill: $color-btn-action;
        }
    }

    &--bind {
        border: 1px solid $color-btn-action-bind !important;
        background: $color-btn-action-bind;

        svg {
            fill: $color-white;
        }

        &:hover {
            color: $color-btn-action-bind;

            svg {
                fill: $color-btn-action-bind;
            }
        }
    }
}