.modal {
    z-index: 9999999;

    &__overlay {
      z-index: 999;
    }

    &__close {
      @include transition($duration: .15s);
      color: $color-white;

      position: absolute;
      top: 10px;
      right: 10px;
      width: 30px;
      height: 30px;
      border-radius: 50%;

      font-size: 18px;

      &::before {
        position: relative;
        left: 0px;
      }
    }

    .modal__content,
    .modal__header {
      max-width: 545px;
      margin: 40px auto 0;

      &--cookie {
        max-width: 660px !important;
      }
    }

    .modal__content {
      margin: 0 auto 40px !important;

      &--cookie {
        margin: 0 auto 20px !important;
      }

      &--cookieLayer {
        margin: 0 auto !important;
        padding: 0 !important;
      }
    }

    .modal__container {
        border-radius: 7px;
        background-color: #ffffff;
        margin: 0 15px;

        color: $color-white;
        position: relative;

        max-width: 600px;

        overflow-y: scroll;
        -ms-overflow-style: none;

      &::-webkit-scrollbar {
          display: none;
      }

      &--cookie {
        max-width: 660px !important;
      }

    }

    &__header {
      justify-content: center !important;
      margin: 20px 0 40px;
      display: block !important;
      text-align: center;

        font-family: $font-family-bold;
        font-size: 22px;
        line-height: 30px;
        color: $color-default-headline;

        text-align: center;

        P {
            font-family: $font-family-regular;
            font-size: 14px;
            line-height: 22px;
            color: $color-default-copy;
            margin-top: 10px;

            text-align: center;

        }

        &--cookie {
          display: flex !important;
          margin: 20px 0 0 0 !important;
          justify-content: flex-start !important;
          padding: 0 10px !important;

          img {
            max-height: 38px;
          }

          .logo-title {
            font-family: $font-family-semi-bold;
            font-size: 18px;
            line-height: 22px;

            margin-left: 15px;
          }
        }
    }

    input {
        background-color: #eff0f6;
    }

    #purchaseSuccess-content,
    #purchaseError-content {
      img {
        margin: 25px auto;
      }
    }

    &__content {
        padding: 25px 50px 5px;

        &--cookie {
          padding: 35px 0 5px;

            p {
              font-family: $font-family-regular;
              font-size: 14px;
              line-height: 22px;
              margin-top: 15px;
              text-align: left;
          }

          .btn {
            width: 100%;
          }
        }

        @include media-breakpoint-down(md) {
            padding: 25px 0 5px;
        }

        .cookie-cta {
          text-align: center;

          span.btn {
            margin-right: 15px;

            @include media-breakpoint-down(sm) {
              margin-right: 0;
            }
          }

          .btn {
            width: auto;
            display: inline-block;

            @include media-breakpoint-down(sm) {
              margin-bottom: 10px;
              display: block;
              width: 100%;
            }
          }
        }

        .cookie-settings {
          list-style: none;
          margin: 20px 0;
          border-top: 1px solid rgba(0, 0, 0, 0.1);

          &__item {
            position: relative;
            padding: 20px 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            label {
              font-family: $font-family-bold;
              color: $color-default-headline;
              font-size: 16px;
              line-height: 22px;
            }
          }
        }

        .cookie-links {
          margin: 20px 0;

          a {
            font-family: $font-family-regular;
            font-size: 13px;
            line-height: 22px;
            color: rgba(24, 30, 45, 0.69);

            &:not(:last-of-type) {
                margin-right: 20px;
            }

            &:last-of-type {
              float: right;
              text-decoration: underline;
              font-family: $font-family-bold;
            }


            &:hover {
                color: $color-default-headline;
                text-decoration: none;
            }
          }
        }

        .form-field__wrapper-button {
            margin: 40px auto 0;
            text-align: center;

            span {
              margin-right: 15px;

              @include media-breakpoint-down(sm) {
                  margin-right: 0;
                  margin-bottom: 15px;
              }
            }

            .btn {
              display: inline-block;
                @include media-breakpoint-down(sm) {
                    display: block;
                    width: 100%;
                }
            }
        }

        .purchase-confirmation {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 15px;
          line-height: 22px;

          font-family: $font-family-regular;
          font-style: italic;
          color: $color-btn-download;

          a {
            text-decoration: underline;
            font-family: $font-family-semi-bold-italic;
            color: $color-btn-download;
            margin-left: 5px;

            &:hover {
                text-decoration: none;
            }
        }

          svg {
            margin-right: 10px;
          }
        }
    }
  }
