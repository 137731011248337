@font-face {
  font-family: 'HKGrotesk Regular';
  font-style: normal;
  font-weight: inherit;
  src: url('../assets/fonts/HKGrotesk-Regular.woff2') format('woff2'),
       url('../assets/fonts/HKGrotesk-Regular.woff') format('woff');
}

@font-face {
  font-family: 'HKGrotesk Semi Bold';
  font-style: normal;
  font-weight: inherit;
  src: url('../assets/fonts/HKGrotesk-SemiBold.woff2') format('woff2'),
       url('../assets/fonts/HKGrotesk-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'HKGrotesk Semi Bold Italic';
  font-style: normal;
  font-weight: inherit;
  src: url('../assets/fonts/HKGrotesk-SemiBoldItalic.woff2') format('woff2'),
       url('../assets/fonts/HKGrotesk-SemiBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'HKGrotesk Bold';
  font-style: normal;
  font-weight: inherit;
  src: url('../assets/fonts/HKGrotesk-Bold.woff2') format('woff2'),
       url('../assets/fonts/HKGrotesk-Bold.woff') format('woff');
}