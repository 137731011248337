.dashboard__shop {
    margin-bottom: 100px;

    @include media-breakpoint-up(md) {
        margin-bottom: 150px;
    }

    .global-headline--large {
        margin: 60px auto;
    }

    &__product {
        justify-content: center;
        list-style: none;
        margin-top: 50px;

        @include media-breakpoint-up(md) {
            margin-top: 100px;
        }

        &.two-items {
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            @include media-breakpoint-up(lg) {
                grid-gap: 100px;
                grid-template-columns: 350px 350px;
            }

            @include media-breakpoint-down(md) {
                grid-gap: 50px;
            }

            @include media-breakpoint-down(sm) {
                grid-template-columns: repeat(1, 1fr);
                grid-gap: 75px;
            }
        }

        &.three-items {
            display: grid;
            grid-template-columns: repeat(3, 1fr);

            @include media-breakpoint-up(lg) {
                grid-gap: 75px;
            }

            @include media-breakpoint-down(md) {
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 50px;
            }

            @include media-breakpoint-down(sm) {
                grid-template-columns: repeat(1, 1fr);
                grid-gap: 75px;
            }
        }

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }

        &-item {
            width: 100%;
            max-width: 350px;

            box-shadow: 0 6px 12px rgba(48, 54, 77, 0.12);
            border-radius: 15px;
            background: $color-white;

            padding: 60px 30px 30px 30px;
            text-align: center;

            position: relative;
            margin: 0 auto;

            img {
                width: 100%;
                max-height: 100px;
                margin-bottom: 20px;
            }

            h3 {
                font-family: $font-family-bold;
                color: $color-black;
                font-size: 17px;
                line-height: 28px;

                margin: 0 0 15px;
                text-align: center;

                @include media-breakpoint-down(md) {
                    font-size: 14px;
                    line-height: 26px;
                }
            }

            span {
                font-family: $font-family-semi-bold-italic;
                font-size: 14px;
                line-height: 22px;
                color: rgba(0, 0, 0, 0.75);
                text-align: center;
                display: block;
                margin: 0 0 15px;
            }

            p {
                font-family: $font-family-regular;
                color: rgba(0, 0, 0, 0.48);
                font-size: 14px;
                line-height: 22px;
                text-align: center;
                display: block;

                margin: 0 0 20px;
            }

            .form-field {
                text-align: left;
            }

            input,
            select {
                background-color: $color-forms-my-account;
            }

            select {
                /* Some browsers will not display the caret when using calc, so we put the fallback first */
                background: url('/assets/img/arrow-down.svg') $color-forms-my-account no-repeat 98.5% !important; /* !important used for overriding all other customisations */
                background: url('/assets/img/arrow-down.svg') $color-forms-my-account no-repeat calc(100% - 15px) !important; /* Better placement regardless of input width */

            }

            .btn {
                margin-top: 10px;
            }
        }
    }

    &__info {
        margin: 150px auto -50px;
        width: 100%;
        text-align: center;

        > div {
            background: rgba(69, 72, 176, 0.07);
            border-radius: 100px;
            padding: 10px 15px;

            font-family: $font-family-regular;
            font-style: italic;
            color: $color-btn-download;

            display: inline-block;
            align-items: center;

            svg {
                margin-right: 10px;
                display: inline-block;
                max-width: 22px;
                position: relative;
                top: -2px;
            }

            a {
                text-decoration: underline;
                font-family: $font-family-semi-bold-italic;
                color: $color-btn-download;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}