.dashboard__proxy-usage {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 45px;
    margin-top: 30px;

    @include media-breakpoint-down(md) {
        grid-template-columns: repeat(1, 1fr);
    }

    &__header {
        display: flex;
        position: relative;

        > img {
            position: absolute;
            right: 0;
            top: -20px;

            @include media-breakpoint-between(lg, xl) {
                display: none;
            }
        }
    }

    &__dropdown {
        margin: 15px 0 0 0;

        select {
            color: $color-black;
            box-shadow: 0 6px 12px rgba(48, 54, 77, 0.04);
            border-radius: 10px;
            border: 1px solid rgba(0, 0, 0, 0.13);
            max-width: 215px;
        }
    }

    &__filter {
        display: inline-block;
        width: 100%;
        margin-top: 30px;

        .global-headline {
            margin: 20px 0 17px;
            display: block;
        }

        span {
            font-family: $font-family-regular;
            color: rgba(13, 19, 42, 0.76);
        }

        nav {
            float: right;
        }

        input[type="radio"]{
            display: none;

            &:checked+label{
              background-color: $color-bg-filter-usage;
              border-color: $color-bg-filter-usage;
              color: $color-white;
            }
        }

        label {
            font-family: $font-family-semi-bold;
            display: inline-block;
            padding: 5px;
            border: 1px solid transparent;
            border-radius: 5px;
            color: $color-bg-filter-usage;
            font-size: 12px;
            line-height: 12px;

             &:hover {
                cursor:pointer;
            }
        }
    }

    &__chart {
        margin-top: 35px;
        width: 100%;
        max-width: 100%;

        .chart {
            max-width: 100%;
        }
    }

    &__sitelist {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 30px;

        margin-top: 35px;

        max-height: 265px;
        overflow-y: scroll;

        scrollbar-width: none; /* For Firefox */
        -ms-overflow-style: none; /* For Internet Explorer and Edge */

        @include media-breakpoint-up(xxl) {
            grid-template-columns: repeat(5, 1fr);
        }

        @include media-breakpoint-only(xl) {
            grid-template-columns: repeat(3, 1fr);
        }

        @include media-breakpoint-only(lg) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include media-breakpoint-only(md) {
            grid-template-columns: repeat(3, 1fr);
        }

        @include media-breakpoint-down(sm) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include media-breakpoint-down(xs) {
            grid-template-columns: repeat(1, 1fr);
        }

        li {
            background-color: $color-global-headline-blue;
            color: $color-white;

            font-family: $font-family-bold;
            font-size: 14px;
            line-height: 22px;

            display: flex;
            align-items: center;
            justify-content: center;

            border-radius: 110px;

            padding: 10px 20px;

            svg {
                margin-left: 10px;
            }
        }
    }
}

.dashboard__proxy-usage__sitelist::-webkit-scrollbar {
    width: 0px; /* For Chrome, Safari, and Opera */
}