.progress-bar {
    width: 100%;
    height: 6px;
    border-radius: 10px;
    margin-top: 10px;

    &.plan-1 {
        background-color: $color-progress-bar-1 !important;
    }

    &.plan-2 {
        background-color: $color-progress-bar-2 !important;
    }

    &.plan-3 {
        background-color: $color-progress-bar-3 !important;
    }
}

.progress-bar-fill {
    @include transition;
    display: block;
    height: 6px;
    background-color: #659cef;
    border-radius: 10px;

    .plan-1 & {
        background-color: $color-progress-bar-1-active !important;
    }

    .plan-2 & {
        background-color: $color-progress-bar-2-active !important;
    }

    .plan-3 & {
        background-color: $color-progress-bar-3-active !important;
    }
}