.dashboard {
    @include transition;
    position: relative;
    margin-left: auto;
    padding-left: 270px;
    margin-left: 60px;
    height: 100vh;
    max-width: 1600px;


    @include media-breakpoint-down(lg) {
        padding-left: 0;
        margin-left: 0;
    }

    @include media-breakpoint-up(xxl) {
        margin-left: 110px;
        max-width: none;
    }

    @include media-breakpoint-down(sm) {
        height: 100%;
    }


    &__header {
        display: flex;
        align-items: center;

        padding: 20px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.07);

        width: 100%;
        z-index: 9999;

        @include media-breakpoint-down(lg) {
            .wrapper {
                display: flex;
                align-items: center;
            }
        }

        @include media-breakpoint-down(sm) {
            flex-direction: column;
            padding: 25px 0;
            align-items: baseline;
        }

        .wrapper {
            display: flex;
            align-items: center;
        }


        .global-headline--medium {
            color: rgba(24, 30, 45, 0.46);
            font-family: $font-family-regular;
            display: block;
        }

        img {
            margin-right: 25px;
            max-width: 55px;

            @include media-breakpoint-down(lg) {
                display: none
            }
        }

    }

    &__main {
        position: relative;
        z-index: 0;
    }

    &__row {
        margin: 25px 0;

        &.row-2 {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 20px;

            @include media-breakpoint-up(xxl) {
                grid-gap: 60px;
            }

            @include media-breakpoint-down(lg) {
                grid-template-columns: repeat(1, 1fr);
            }
        }

        &.row-2-sl {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-columns: 3fr 9fr;

            grid-gap: 20px;

            @include media-breakpoint-up(xxl) {
                grid-gap: 60px;
            }

            @include media-breakpoint-down(lg) {
                grid-template-columns: repeat(2, 1fr);
                grid-template-columns: 6fr 6fr;
            }

            @include media-breakpoint-down(md) {
                grid-template-columns: repeat(1, 1fr);
            }
        }

        &.line-bottom {
            border-bottom: 1px solid rgba(0, 0, 0, 0.07);
            padding-bottom: 30px;
            margin-bottom: 30px;
        }
    }

    &__inner {
        box-shadow: 0 6px 12px rgba(48, 54, 77, 0.04);
        border-radius: 5px;
        background-color: $color-white;
        padding: 25px 20px 20px;

        .global-headline {
            display: block;
        }
    }
}