.role {
    border-radius: 37px;
    font-family: $font-family-semi-bold;
    font-size: 14px;
    line-height: 22px;

    padding: 3px 10px;
    display: inline-flex;
    align-items: center;


    &::before {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 10px;
    }

    &--standart {
        color: #de3814;
        background-color: rgba(222, 56, 20, 0.1);

        &::before {
            background: #de3814;
        }
    }

    &--premium {
        color: #430C83;
        background: rgba(67, 12, 131, 0.1);

        &::before {
            background: #430C83;
        }
    }

    &--elite {
        color: #0051B7;
        background: rgba(0, 81, 183, 0.1);

        &::before {
            background: #0051B7;
        }
    }
}