.dashboard-user-nav {
    margin: 0 0 0 auto;
    list-style: none;

    @include media-breakpoint-down(sm) {
        margin: 20px 0 0 auto;
    }

    &__item {
        display: block;
        position: relative;

        &.rotate {
            label {
                border-radius: 5px 5px 0 0;
            }
        }

        label,
        a {
            @include transition;

            display: flex;
            align-items: center;

            width: 215px;
            padding: 15px;

            font-family: $font-family-semi-bold;
            font-size: 16px;
            line-height: 24px;
            color: rgba($color-black, 50%);
            background-color: $color-white;

            @include media-breakpoint-down(md) {
                font-size: 14px;
                line-height: 22px;
            }
        }

        label {
            box-shadow: 0 6px 12px rgba(48, 54, 77, 0.04);
            border-radius: 5px;
            position: relative;
            z-index: 10;

            padding-left: 41px;

            svg {
                @include transition;

                fill: $color-svg-dashboard-header;
                margin-left: 45px;
                width: 15px;
                height: 15px;
            }
        }

        a {

            &:hover {
                background-color: $color-bg-dashboard-header-hover-navigation;
                text-decoration: none;
                color: $color-white;
            }

            svg {
                fill: $color-black;
                margin-right: 10px;
            }
        }

        .sub-nav {
            position: absolute;
            top: 50px;
            z-index: 9;
            list-style: none;
            box-shadow: 0 6px 12px rgba(48, 54, 77, 0.15);


            &__item {
                &:not(:first-of-type) {
                    a {
                        &:last-of-type {
                            border-radius: 0 0 5px 5px;
                            position: relative;
                        }

                        &::before {
                            content: '';
                            width: 90%;
                            height: 2px;
                            display: block;
                            background-color: rgba(0, 0, 0, 0.05);

                            position: absolute;
                            top: 0;
                            left: 10px;
                        }
                    }
                }
            }
        }
    }
}

.burger-menu {
    position: relative;
    width: 25px;
    height: 18px;
    padding: 1px 10px 0 0;
    z-index: 99;
    cursor: pointer;
}

.burger {
    position: relative;
    background: $color-black;
    width: 15px;
    height: 2px;
    top: 8px;
    left: 0;
    opacity: 1;
}
.burger::before {
    position: relative;
    background: $color-black;
    width: 25px;
    height: 2px;
    top: 8px;
    content: "";
    display: block;
}
.burger::after {
    position: relative;
    background: $color-black;
    width: 25px;
    height: 2px;
    bottom: 10px;
    content: "";
    display: block;
}

.burger::after,
.burger::before,
.burger {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.burger-menu.menu-on .burger::after {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    bottom: 2px;
}

.burger-menu.menu-on .burger::before {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    top: 0px;
}

.burger-menu.menu-on .burger {
    background: rgba(255, 255, 255, 0);
}

.dashboard-user-nav input[type="checkbox"],
.dashboard-user-nav ul span.drop-icon {
    display: none;
}

.dashboard-user-nav .sub-nav {
    display: none;
}

.dashboard-user-nav input[type="checkbox"]:checked + .sub-nav {
    display: block;
}
