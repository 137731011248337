.menu {
    width: 100%;

    &__item {
        margin-bottom: 25px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 20px;
        }

        a {
            @include transition;
            font-family: $font-family-semi-bold;
            font-size: 16px;
            color: rgba(255, 255, 255, 0.54);

            width: 100%;
            display: flex;
            align-items: center;

            padding: 15px 30px;

            @include media-breakpoint-up(xxl) {
                padding: 15px 50px;
            }

        }

        .sub-menu a {
            @include transition;
            font-size: 14px;
            padding: 10px 50px 10px 68px;

            @include media-breakpoint-up(xxl) {
                padding: 10px 50px 10px 88px;
            }

            &.is-active,
            &:hover {
                background-color: $color-bg-hover-navigation;
                color: $color-white;
            }
        }

        &.is-active,
        &:hover {
            > a {
                background-color: $color-bg-hover-navigation;
                color: $color-white;
            }

            a {
                text-decoration: none;
            }

            svg {
                fill: $color-white;
            }
        }

        svg {
            @include transition;
            fill: rgba(255, 255, 255, 0.54);
            width: 100%;
            height: 100%;
            max-width: 20px;

            margin-right: 15px;
        }

        label {
            display: flex;
            align-items: center;
            margin-left: 15px;

            width: 55px;
            height: 22px;

            svg {
                max-width: 15px;
            }
        }
    }
}

.menu input[type="checkbox"],
.menu ul span.drop-icon {
  display: none;
}

.menu .sub-menu {
    display: none;
}

.menu input[type="checkbox"]:checked + .sub-menu {
    display: block;
}