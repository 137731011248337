.dashboard-user-bar {
    margin-top: 20px;
    display: flex;
    align-items: center;

    @include media-breakpoint-down(md) {
        display: block;
    }

    .wrapper {
        display: flex;
        align-items: center;

        width: 100%;

        @include media-breakpoint-down(lg) {
            .wrapper {
                display: flex;
                align-items: center;
            }
        }

        @include media-breakpoint-down(sm) {
            flex-direction: column;
            align-items: baseline;
            align-items: center;
        }

        img {
            max-width: 70px;
            border-radius: 50%;
            margin-right: 20px;

            @include media-breakpoint-down(sm) {
                margin-right: 0;
            }
        }

        h2 {
            @include media-breakpoint-down(sm) {
                display: flex;
                flex-direction: column;
                align-items: baseline;
                width: 100%;
                margin-top: 10px;

                align-items: center;
            }

            p {
                display: block;
            }

            &.user-binded {
                p {
                    color: rgba(0, 0, 0, 0.5);
                    font-size: 15px;
                    line-height: 22px;
                }
            }
        }

        .role {
            margin-left: 10px;

            @include media-breakpoint-down(sm) {
                margin: 5px 0;
            }
        }
    }

    &__nav {
        display: flex;
        align-items: center;

        @include media-breakpoint-down(md) {
            margin-top: 20px;
        }

        @include media-breakpoint-down(sm) {
            justify-content: center;
            width: 100%;
        }

        form {
            display: flex;
            align-items: center;

            .btn {
                &:not(:last-of-type) {
                    margin-right: 10px;
                }
            }
        }
    }
}