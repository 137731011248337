.dashboard__stats {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;

    @include media-breakpoint-down(sm) {
        grid-template-columns: repeat(1, 1fr);
    }

    &-list {
        list-style: none;
        margin: 15px auto 0;

        &__item {
            margin-bottom: 20px;
            position: relative;

            p {
                font-family: $font-family-bold;
                font-size: 15px;
                line-height: 22px;
                color: $color-default-headline;
                display: flex;
                align-items: center;

                span {
                    font-family: $font-family-regular;
                    font-style: italic;
                    margin-left: auto;
                }
           }
        }
    }

    &-cta {
        display: block;
        display: inline-block;
        text-align: center;
        width: 100%;
        margin: 20px auto 0;

        @include media-breakpoint-down(sm) {
            margin: 10px auto 0;
        }

        .btn {
            min-width: auto;
        }
    }
}

.dashboard__invoices {
    overflow: hidden;

    &-table {
        margin-top: 15px;
        max-height: 250px;

        overflow-x: hidden;
        position: relative;

        scrollbar-width: none; /* For Firefox */
        -ms-overflow-style: none; /* For Internet Explorer and Edge */

        @include media-breakpoint-down(sm) {
            overflow: scroll;
        }
    }
}
.dashboard__invoices-table::-webkit-scrollbar {
    width: 0px; /* For Chrome, Safari, and Opera */
}