/**
 * Colors
 */

$color-white: #fff;
$color-black: #000;
$color-default-headline: #181e2d;
$color-default-copy: rgba(24, 30, 45, 0.59);

$color-bg-default: #F1F7FF;
$color-btn-red: #de3814;
$color-btn-download: #4548b0;
$color-btn-action: #FF507A;
$color-btn-action-bind: #4548B0;
$color-error: $color-btn-red;

$color-global-headline-blue: #4548b0;
$color-bg-login: $color-default-headline;
$color-bg-dashboard-sidebar: #181e2d;
$color-bg-hover-navigation: rgba(218, 77, 46, 0.26);
$color-bg-dashboard-header-hover-navigation: #BC5035;
$color-svg-dashboard-header: #A1A1A1;

$color-progress-bar-1: #dae6f5;
$color-progress-bar-1-active: #0051b7;
$color-progress-bar-2: rgba(67, 12, 131, 0.07);
$color-progress-bar-2-active: #430c83;
$color-progress-bar-3: rgba(168, 36, 214, 0.1);
$color-progress-bar-3-active: #a824d6;

$color-bg-filter-usage: #da4d2e;
$color-circle-before: #FBBAAF;
$color-circle-filled: #F36044;

$color-text-area-bg-color: #eff0f6;
$color-forms-my-account: #f1f7ff;