.dashboard__generated-proxies {
    .dashboard-flex {
        @include media-breakpoint-up(md) {
            display: flex;
            align-items: center;
            width: 100%;
            position: relative;
        }
    }

    &__cta {
        margin: 25px 0;

        @include media-breakpoint-up(md) {
            margin: 0 0 0 auto;
        }

        button {
            &:not(:last-of-type) {
                margin-right: 15px;
            }

            @include media-breakpoint-down(sm) {
                width: 100%;

                &:not(:last-of-type) {
                    margin-bottom: 15px;
                    margin-right: 0;
                }
            }
        }
    }

    &__list {
        margin-top: 25px;

        .input-textarea {
            min-height: 300px;
            resize: none;

            @include media-breakpoint-down(sm) {
                min-height: 225px;
            }
        }
    }
}