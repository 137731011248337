.toggle {
	cursor: pointer;
	display: inline-block;
}

.toggle-switch {
	display: inline-block;
	background: #ccc;
	border-radius: 16px;
	width: 60px;
	height: 30px;
	position: relative;
	vertical-align: middle;
	transition: background 0.25s;
    margin-right: 10px;

	&:before,
	&:after {
		content: "";
	}
	&:before {
		display: block;
		background: linear-gradient(to bottom, #fff 0%,#eee 100%);
		border-radius: 50%;
		box-shadow: 0 0 0 1px rgba(0,0,0,0.15);
		width: 26px;
		height: 26px;
		position: absolute;
		top: 2px;
		left: 3px;
		transition: left 0.25s;
	}
	.toggle:hover &:before {
		background: linear-gradient(to bottom, #fff 0%,#fff 100%);
	}
	.toggle-checkbox:checked + & {
		background: $color-btn-red;

		&:before {
			left: 27px;
		}
	}
}

.toggle-checkbox {
	position: absolute;
	visibility: hidden;
}

.toggle-label {
	margin-left: 5px;
	position: relative;
	top: 2px;
}