.dashboard__my-account {
    margin-top: 30px;

    &__form {
        margin: 35px 0 0 0;

        input,
        select {
            background-color: $color-forms-my-account;
        }

        select {
            /* Some browsers will not display the caret when using calc, so we put the fallback first */
            background: url('/assets/img/arrow-down.svg') $color-forms-my-account no-repeat 98.5% !important; /* !important used for overriding all other customisations */
            background: url('/assets/img/arrow-down.svg') $color-forms-my-account no-repeat calc(100% - 15px) !important; /* Better placement regardless of input width */

        }

        .form-field {
            margin-bottom: 30px;
        }

        .form-field__wrapper-save {
            text-align: center;
            margin: 20px 0 0 0;
        }
    }

    &__points {
        text-align: center;
        margin-bottom: 30px;

        h5 {
            font-family: $font-family-bold;
            font-size: 100px;
            line-height: 161.3%;
            color: $color-btn-red;

            margin: 0 auto;
            text-align: center;
        }
    }

    &__gift {
        margin-bottom: 30px;

        .form-field {
            display: flex;
            align-items: center;
            justify-content: center;

            @include media-breakpoint-only(xl) {
                display: flex;
                flex-direction: column;
            }

            @include media-breakpoint-down(sm) {
                display: flex;
                flex-direction: column;
            }

            input,
            label,
            button {
                @include media-breakpoint-only(xl) {
                    display: block;
                }

                @include media-breakpoint-down(sm) {
                    display: block;
                }
            }

            input {
                max-width: 300px;
                margin: 0 25px;

                @include media-breakpoint-only(xl) {
                    width: 100%;
                    margin: 15px 0;
                }

                @include media-breakpoint-down(sm) {
                    width: 100%;
                    margin: 15px 0;
                }
            }

            label {
                margin-bottom: 0;
            }
        }

        input {
            background-color: $color-forms-my-account;
        }
    }

    &__payment {

        > div:first-of-type {
            display: flex;
            align-items: center;

            button {
                margin-left: auto;
                min-width: auto;
            }
        }

        &-table {
            margin-top: 20px;
            max-height: 162px;
            overflow-x: hidden;
            scrollbar-width: none; /* For Firefox */
            -ms-overflow-style: none; /* For Internet Explorer and Edge */

            button {
                svg {
                    margin-right: 15px;

                    position: relative;
                    top: 2px;

                    &:hover {
                        fill: $color-btn-download;
                        cursor: pointer;
                    }
                }

                &.is-active {
                    svg {
                        fill: $color-btn-download;
                        cursor: pointer;
                    }
                }
            }

        }
    }
}

.dashboard__my-account__payment-table::-webkit-scrollbar {
    width: 0px; /* For Chrome, Safari, and Opera */
}